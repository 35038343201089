<template>
  <div>
    <div v-loading="emailConfigLoading" :element-loading-text="loadingText">
      <el-scrollbar style="padding: 10px; max-height: 485px">
        <div
          style="
            padding: 10px;
            max-height: 485px;
            overflow: scroll;
            scrollbar-width: thin;
          "
        >
          <div v-if="checkBulkSendCondition()">
            Filter
            <el-select
              v-model="entityNotificationBody.filter_id"
              clearable
              placeholder="Select saved filter"
              style="width: 97.5%; margin-bottom: 10px"
            >
              <el-option
                v-for="filter in selectedNotifyEntityFilters"
                :key="filter._id"
                :label="filter.filter_name"
                :value="filter._id"
                >{{ filter.filter_name }}</el-option
              >
            </el-select>
          </div>
          <div>
            <div>
              <el-row>
                <el-col :span="12">
                  <el-checkbox :disabled="true" v-model="checkTo"
                    >To</el-checkbox
                  >
                  <el-checkbox v-model="checkCC">CC</el-checkbox>
                  <el-checkbox v-model="checkBCC">BCC</el-checkbox>
                  <el-popover
                    placement="top-start"
                    width="450"
                    trigger="hover"
                    content="Ensure that duplicate emails are not entered across different receiver types (To, CC, BCC), thereby preventing inadvertent triggering of emails, the system has been configured to 
                            validate and restrict such occurrences."
                  >
                    <el-button class="info-button" slot="reference">
                      <i class="el-icon-info"></i
                    ></el-button>
                  </el-popover>
                </el-col>
                <el-col :span="12">
                  <div>
                    <a @click="chooseFromEmailTemplates" class="pop-up-button"
                      >Choose from email templates</a
                    >
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <el-row>
                    <el-col :span="12">
                      <el-select
                        v-model="email_fields.entity_fields"
                        placeholder="Receiver emails"
                        size="small"
                        clearable
                        filterable
                        multiple
                        remote
                        default-first-option
                        class="email-field"
                        collapse-tags
                      >
                        <el-option
                          v-for="(field, i) in emailFields"
                          :key="field + i"
                          :value="field.template_key"
                          :label="field.label"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        v-model="email_fields.custom_emails"
                        placeholder="Custom Emails"
                        size="small"
                        class="email-field"
                        multiple
                        clearable
                        filterable
                        collapse-tags
                        default-first-option
                        allow-create
                      >
                        <el-option
                          v-for="item in email_fields &&
                          email_fields.custom_emails"
                          :key="item"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row class="second-row">
                    <el-col :span="12">
                      <el-select
                        v-model="email_fields.user_types_emails"
                        placeholder="User Types Emails"
                        size="small"
                        :disabled="isApplicationUserSide"
                        v-loading="userEmailsLoading"
                        class="email-field"
                        multiple
                        clearable
                        filterable
                      >
                        <el-option-group
                          v-for="group in userTypesEmails"
                          :key="group.label"
                          :label="getLabelByUserTypeId(group.label)"
                        >
                          <el-option
                            v-for="item in group.options"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                      <el-select
                        v-model="email_fields.filters"
                        clearable
                        multiple
                        class="email-field"
                        placeholder="Select relational entity"
                        size="small"
                        default-first-option
                      >
                        <el-option
                          value="CONTACTS"
                          label="Contacts"
                          v-if="
                            currentEntity &&
                            currentEntity.entity_type == 'BUSINESS'
                          "
                        >
                        </el-option>
                        <el-option
                          value="SELF"
                          label="Self"
                          v-if="
                            currentEntity &&
                            currentEntity.entity_type == 'INDIVIDUAL'
                          "
                        >
                        </el-option>
                        <el-option
                          v-for="field in getEntityFields(allFields)"
                          :key="field.key"
                          :label="'Field - ' + field.label"
                          :value="'FIELD#' + field.key"
                        >
                        </el-option>
                        <el-option
                          v-for="relation in selectedEntityRelationships"
                          :key="relation._id"
                          :label="relation.entity_name"
                          :value="'RELATION#' + relation._id"
                          >{{ relation.entity_name }} ({{
                            relation.relationship_title
                          }})
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <el-col :span="24">
                  <div v-if="checkCC">
                    <el-row> <el-col> CC : </el-col></el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-select
                          v-model="cc_email_fields.entity_fields"
                          placeholder="Cc emails"
                          size="mini"
                          multiple
                          clearable
                          filterable
                          collapse-tags
                          class="email-field"
                        >
                          <el-option
                            v-for="(field, i) in getFieldsByType(
                              [],
                              emailFields
                            )"
                            :key="field + i"
                            :value="field.template_key"
                            :label="field.label"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-select
                          v-model="cc_email_fields.custom_emails"
                          placeholder="Custom Cc Emails"
                          size="mini"
                          class="email-field"
                          multiple
                          clearable
                          filterable
                          collapse-tags
                          allow-create
                          default-first-option
                        >
                          <el-option
                            v-for="item in cc_email_fields &&
                            cc_email_fields.custom_emails"
                            :key="item"
                            :label="item"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row class="second-row">
                      <el-col :span="12">
                        <el-select
                          v-model="cc_email_fields.user_types_emails"
                          placeholder="User Types Emails"
                          size="mini"
                          v-loading="userEmailsLoading"
                          :disabled="isApplicationUserSide"
                          multiple
                          clearable
                          class="email-field"
                          filterable
                          collapse-tags
                        >
                          <el-option-group
                            v-for="group in userTypesEmails"
                            :key="group.label"
                            :label="getLabelByUserTypeId(group.label)"
                          >
                            <el-option
                              v-for="item in group.options"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-select
                          v-model="cc_email_fields.filters"
                          clearable
                          placeholder="Select relational entities"
                          size="mini"
                          class="email-field"
                          multiple
                        >
                          <el-option
                            value="CONTACTS"
                            label="Contacts"
                            v-if="
                              currentEntity &&
                              currentEntity.entity_type == 'BUSINESS'
                            "
                          >
                          </el-option>
                          <el-option
                            value="SELF"
                            label="Self"
                            v-if="
                              currentEntity &&
                              currentEntity.entity_type == 'INDIVIDUAL'
                            "
                          >
                          </el-option>
                          <el-option
                            v-for="relation in selectedEntityRelationships"
                            :key="relation._id"
                            :label="relation.relationship_title"
                            :value="'RELATION#' + relation._id"
                            >{{ relation.relationship_title }}</el-option
                          >
                        </el-select>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div v-if="checkBCC">
                    <el-row>
                      <el-col>BCC :</el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-select
                          v-model="bcc_email_fields.entity_fields"
                          placeholder="Bcc emails"
                          size="mini"
                          multiple
                          clearable
                          filterable
                          collapse-tags
                          class="email-field"
                        >
                          <el-option
                            v-for="(field, i) in getFieldsByType(
                              [],
                              emailFields
                            )"
                            :key="field + i"
                            :value="field.template_key"
                            :label="field.label"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-select
                          v-model="bcc_email_fields.custom_emails"
                          placeholder="Custom Bcc Emails"
                          size="mini"
                          multiple
                          clearable
                          class="email-field"
                          filterable
                          collapse-tags
                          default-first-option
                          allow-create
                        >
                          <el-option
                            v-for="item in bcc_email_fields &&
                            bcc_email_fields.custom_emails"
                            :key="item"
                            :label="item"
                            :value="item"
                          ></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row class="second-row">
                      <el-col :span="12">
                        <el-select
                          v-model="bcc_email_fields.user_types_emails"
                          placeholder="User Types Emails"
                          size="mini"
                          v-loading="userEmailsLoading"
                          :disabled="isApplicationUserSide"
                          multiple
                          clearable
                          filterable
                          class="email-field"
                          collapse-tags
                        >
                          <el-option-group
                            v-for="group in userTypesEmails"
                            :key="group.label"
                            :label="getLabelByUserTypeId(group.label)"
                          >
                            <el-option
                              v-for="item in group.options"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-select
                          v-model="bcc_email_fields.filters"
                          clearable
                          placeholder="Select relational entities"
                          size="mini"
                          multiple
                          class="email-field"
                        >
                          <el-option
                            value="CONTACTS"
                            label="Contacts"
                            v-if="
                              currentEntity &&
                              currentEntity.entity_type == 'BUSINESS'
                            "
                          >
                          </el-option>
                          <el-option
                            value="SELF"
                            label="Self"
                            v-if="
                              currentEntity &&
                              currentEntity.entity_type == 'INDIVIDUAL'
                            "
                          >
                          </el-option>
                          <el-option
                            v-for="relation in selectedEntityRelationships"
                            :key="relation._id"
                            :label="relation.relationship_title"
                            :value="'RELATION#' + relation._id"
                            >{{ relation.relationship_title }}</el-option
                          >
                        </el-select>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div style="margin-top: 15px">
            <el-row>
              <el-col :span="12">
                <p style="font-weight: 400; margin-bottom: 0px">
                  Email Subject
                </p>
                <el-input
                  v-model="entityNotificationBody.subject"
                  type="textarea"
                  maxlength="998"
                  class="email-field subject-text-area"
                  rows="2"
                  show-word-limit
                  placeholder="Enter Subject"
                >
                </el-input>
              </el-col>
              <el-col :span="12">
                <p class="para-class" style="margin-bottom: 0px">
                  Select Field to Copy
                  <el-popover
                    placement="top-start"
                    width="420"
                    trigger="hover"
                    content="Click on the right arrow button to add field to subject"
                  >
                    <el-button class="info-button" slot="reference">
                      <i class="el-icon-info"></i
                    ></el-button>
                  </el-popover>
                </p>
                <el-select
                  v-model="copyField"
                  placeholder="Select Field to copy"
                  filterable
                  size="small"
                  class="email-field"
                  @change="copyURL"
                >
                  <el-option
                    v-for="(item, index) of getSupportedSubjectFields()"
                    v-bind:key="index"
                    :label="item.label"
                    :value="item.label"
                  >
                    {{ item.label }} ({{ item.input_type }})
                    <a
                      v-if="item.isSubjectSupported"
                      @click.stop="pushToSubject(item.label)"
                    >
                      <i class="el-icon-right" style="color: #409eff"></i>
                    </a>
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
          <div style="margin-top: 15px">
            <el-row>
              <el-col :span="3">
                <span style="font-weight: 400">Email body</span>
              </el-col>
              <el-col :span="16">
                <a class="pop-up-button" size="mini" @click="openTableConfig">
                  Configure Relational data tables
                </a>
              </el-col>
              <el-col :span="3" style="margin-top: 7px; margin-left: 23px">
                <div
                  style="cursor: pointer; width: 100%; height: 100%"
                  @click="toggleAI()"
                >
                  <icons
                    :iconName="'TryUsingAI'"
                    style="
                      color: #409eff !important;
                      fill: #409eff !important;
                      margin-left: -48px;
                    "
                  ></icons>
                  <div
                    style="margin-left: 33px; margin-top: -25px; color: #409eff"
                  >
                    AI Assist
                  </div>
                </div>
              </el-col>
            </el-row>
            <div
              v-loading="ckeditorLoading"
              :element-loading-text="loadingText"
              style="margin-top: 10px"
            >
              <ckeditor
                v-if="isOpened"
                v-model="entityNotificationBody.body"
                :config="editorConfig"
                :key="editorKey"
              ></ckeditor>
            </div>
          </div>
          <div class="parent-template-class">
            <el-row>
              <div
                @click="chooseFromParentEmailTemplates"
                style="cursor: pointer; color: #409eff; text-align: center"
              >
                <span v-if="!email_parent_content.name"
                  >Choose Parent Template(Header & Footer)</span
                >
                <span v-else>Change Parent Template (Header & Footer)</span>
              </div>
              <div v-if="email_parent_content.name" style="text-align: center">
                Selected Parent : <span>{{ email_parent_content.name }}</span>
                <span>
                  <i
                    style="cursor: pointer"
                    @click="resetParent"
                    class="el-icon-error"
                  ></i
                ></span>
              </div>
            </el-row>
            <el-divider></el-divider>
            <div class="attachments-footer">
              <el-row style="margin-top: 10px">
                <el-col :span="24">
                  <div class="attachments">
                    <el-checkbox v-model="showHiddenFields"
                      >Show Hidden Fields</el-checkbox
                    >
                    <el-checkbox v-model="showTableHiddenFields"
                      >Show Table Hidden Fields</el-checkbox
                    >
                  </div>
                  <div
                    class="attachments"
                    v-if="
                      currentEntity && currentEntity.default_printing_document
                    "
                  >
                    <el-checkbox v-model="sendDataAsAttachment"
                      >Send Data as an attachment</el-checkbox
                    >
                  </div>
                  <div class="d-flex" v-if="sendDataAsAttachment">
                    <div style="margin-right: 5px">
                      Printing name
                      <el-input
                        v-model="sendDataAsAttachmentName"
                        placeholder="File name"
                      ></el-input>
                    </div>
                    <div>
                      Select document template
                      <el-select
                        v-model="sendDataAsAttachmentDocument"
                        placeholder="Select Documents"
                        default-first-option
                        size="medium"
                        style="width: 90%"
                        filterable
                      >
                        <el-option
                          v-for="(doc, i) in entityDocuments"
                          :key="i + '_document'"
                          :label="doc.title"
                          :value="doc.configurable_document_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="attachments">
                    <el-checkbox v-model="hasAttachment"
                      >Attach Documents ?</el-checkbox
                    >
                  </div>
                  <div class="attachments"></div>
                  <div
                    v-if="hasAttachment"
                    style="
                      display: flex;
                      justify-content: center;
                      margin-top: 10px;
                    "
                  >
                    <el-upload
                      class="upload-demo"
                      name="logo"
                      :on-change="uploadFile"
                      action
                      :show-file-list="false"
                      :auto-upload="false"
                      :limit="10"
                      accept=".doc, .pdf, .docx, .txt, .png, .jpg, .jpeg, .xls, .xlsx, .xlsm,"
                      multiple
                      :file-list="files"
                    >
                      <el-button
                        size="medium"
                        type="primary"
                        class="document"
                        style="margin: auto"
                        >Click to upload</el-button
                      >
                    </el-upload>
                    <el-select
                      v-model="selectedDocuments"
                      placeholder="Select Documents"
                      class="document"
                      size="medium"
                      multiple
                      clearable
                      filterable
                      collapse-tags
                    >
                      <el-option
                        v-for="(field, i) in docFields"
                        :key="field + i"
                        :value="field.template_key"
                        :label="field.label"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="hasAttachment" style="margin-top: 10px">
                <el-col :span="24" class="name-types">
                  <div v-if="files && files.length" style="text-align: center">
                    <h4>Uploaded Documents :</h4>
                  </div>
                  <div
                    class="filename"
                    v-for="(file, index) in files"
                    :key="JSON.stringify(file)"
                    style="display: flex; justify-content: center"
                  >
                    <a class="el-upload-list__item-name">
                      <img
                        :src="require('@/assets/img/icons/upload-active.svg')"
                        alt="icon"
                        class="img-active"
                      />
                      <img
                        v-if="
                          file.type == 'image/jpeg' || file.type == 'image/jpg'
                        "
                        :src="require('@/assets/img/icons/JPG.svg')"
                        alt="icon"
                        class="img-fluid"
                      />
                      <img
                        v-else-if="file.type == 'image/png'"
                        :src="require('@/assets/img/icons/PNG.svg')"
                        alt="icon"
                        class="img-fluid"
                      />

                      <img
                        v-else
                        :src="require('@/assets/img/icons/pdf.svg')"
                        alt="icon"
                        class="img-fluid"
                      />
                      {{
                        file.name.includes(".") && file.name.split(".")[0]
                          ? file.name.split(".")[0].includes("_")
                            ? file.name.split(".")[0]
                            : file.name.split(".")[0] &&
                              file.name.split(".")[0].replaceAll("_", " ")
                          : file.name && file.name.replaceAll("_", " ")
                      }}
                      <a @click="deleteItem(index)">
                        <i class="el-icon-circle-close"></i>
                      </a>
                    </a>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <span class="dialog-footer" slot="footer">
        <a
          v-if="!emailConfigLoading && !hideActions"
          :disabled="isTemplateCreated"
          @click="handleSaveAsTemplate"
          class="pop-up-button"
          >Save As Template</a
        >
        <el-button
          type="primary"
          v-loading="emailConfigLoading"
          :disabled="checkIsDisabled()"
          @click="sendNotification"
          class="mr-3"
          >{{ isAutomation ? "Save" : "Send" }}
          <i v-if="!isAutomation" class="el-icon-s-promotion"></i
        ></el-button>
      </span>
    </div>
    <dialog-component
      append-to-body
      :title="'Configure Table'"
      :visible="configureTableDialog"
      @before-close="ConfigureTableDialog"
      :containerWidth="'50%'"
    >
      <div style="margin: 15px">
        <emailRelationalDataTable
          :allEntitiesData="allEntitiesData"
          :data="relationalTableData"
          :fields="allEntityFields"
          :allCompanyDataFields="allCompanyDataFields"
          v-on:save-all-tables="handleTableConfigClose"
        >
        </emailRelationalDataTable>
      </div>
    </dialog-component>
    <!-- </el-dialog> -->
    <dialog-component
      :title="'Email Templates'"
      :visible="emailTemplatesListDialog"
      :containerWidth="'80%'"
      @before-close="emailTemplateListDialog"
      :isShowFooter="false"
    >
      <el-scrollbar wrap-style="padding: 10px; max-height: 560px;">
        <singleEntityTemplatesList
          :content="email_content"
          :entity_id="currentEntity._id"
          type="ENTITY"
          template_type="EMAIL"
          v-on:closeDialog="closeTemplatesList"
        />
      </el-scrollbar>
    </dialog-component>
    <dialog-component
      :title="'Parent Templates(Header Footer)'"
      :visible="emailTemplatesParentListDialog"
      :containerWidth="'100%'"
      @before-close="emailTemplateParentListDialog"
    >
      <el-scrollbar wrap-style="padding: 10px; max-height: 560px;">
        <singleEntityTemplatesList
          :content="email_parent_content"
          entity_id=""
          type="PARENT"
          v-on:closeDialog="closeTemplatesList"
        />
      </el-scrollbar>
    </dialog-component>
    <div
      class="chatgpt"
      :style="{ top: top + 'px', left: left + 'px', position: 'absolute' }"
      @mousedown="startDrag"
    >
      <ChatGpt
        v-if="showAskAi"
        @close-chatbot="CloseChatBot"
        @copy-content="handleCopyContent"
        :entity_fields="allMentionableFields"
        :global_fields="allCompanyDataFields"
        :entityName="currentEntity.name"
        :entity="entityIdObject[form.entity_id] || {}"
        type="Configure_Email"
      />
    </div>
    <dialog-component
      :title="'Save As Template'"
      :visible="saveAsTemplateDialog"
      :containerWidth="'50%'"
      :containerHeight="'46%'"
      :isShowFooter="false"
      @before-close="closeSaveAsTemplateDialog"
    >
      <saveAsTemplate
        :formData="saveAsTemplateForm"
        :allFields="allMentionableFields"
        :relationalFormData="relationalDataForm"
        v-on:handleEmailTemplateCreated="handleTemplateCreated"
      >
      </saveAsTemplate>
    </dialog-component>
  </div>
</template>
<script>
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import { mapGetters } from "vuex";
import axios from "@/config/axios";
import AutomationHelper from "@/mixins/AutomationHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import ChatGpt from "../ChatGpt-EmailTemplates.vue";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
import { postAPICall } from "../../helpers/httpHelper";
// import _ from "lodash";
import { debounce } from "@/helpers/lodashHelper.js";
export default {
  name: "EmailConfig",
  props: [
    "currentEntity",
    "entityData",
    "isSendIndividual",
    "selectedIds",
    "paramsContent",
    "isAutomation",
    "keyValue",
    "allFields",
    "hideActions",
    "emailTemplateId",
    "parentEmailTemplateId",
    "entityName",
  ],
  data() {
    return {
      openEntityNotification: true,
      entityNotificationBody: {
        subject: "",
        body: "",
        filters: [],
      },
      showChatbot: false,
      showAskAi: false,
      isDragging: false,
      startX: 0,
      startY: 0,
      top: 70,
      left: window.innerWidth - 500,

      entityIdObject: {},
      selectedEntityRelationships: [],
      isApplicationUserSide: false,
      emailTemplatesListDialog: false,
      componentCallType: "",
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorKey: 0,
      email_content: {
        body: "",
        subject: "",
        form: {},
      },
      form: {
        entity_id: "",
        filters: "",
        selectedFields: [],
      },
      email_parent_content: {
        header_body: "",
        footer_body: "",
        name: "",
      },
      email_fields: {
        user_types_emails: [],
        entity_fields: [],
        custom_emails: [],
        filters: [],
      },
      cc_email_fields: {
        user_types_emails: [],
        entity_fields: [],
        custom_emails: [],
        filters: [],
      },
      bcc_email_fields: {
        user_types_emails: [],
        entity_fields: [],
        custom_emails: [],
        filters: [],
      },
      saveAsTemplateForm: {},
      allEntitiesData: [],
      entityAllFilters: [],
      filterLoading: false,
      selectedEntityFields: [],
      configureTableDialog: false,
      emailTemplatesParentListDialog: false,
      emailFields: [],
      userTypes: [],
      userTypesEmails: [],
      checkCC: false,
      checkBCC: false,
      checkTo: false,
      loadingText: "",
      allToEmailFields: [],
      userEmailsLoading: false,
      infoDialog: false,
      isOpened: false,
      relationshipsData: [],
      showHiddenFields: true,
      showTableHiddenFields: true,
      allCustomFields: [
        {
          id: "current_date",
          label: "Time - Current Date",
        },
        {
          id: "current_time",
          label: "Time - Current Time",
        },
        {
          id: "current_date_time",
          label: "Time - Current Date Time",
        },
        {
          id: "redirect_link_entity_data_edit",
          label: "Redirect Link - Company User - Data Edit",
        },
        {
          id: "redirect_link_entity_data_view",
          label: "Redirect Link - Company User - Data View",
        },
        {
          id: "redirect_link_ap_entity_data_edit",
          label: "Redirect Link - Application User - Data Edit",
        },
        {
          id: "redirect_link_ap_entity_data_view",
          label: "Redirect Link - Application User - Data View",
        },
      ],
      allCompanyFields: [],
      allCompanyDataFields: [],
      files: [],
      hasAttachment: false,
      isShowError: false,
      docFields: [],
      selectedDocuments: [],
      sendDataAsAttachment: false,
      sendDataAsAttachmentName: "",
      sendDataAsAttachmentDocument: "",
      selectedNotifyEntity: null,
      selectedNotifyEntityFilters: [],
      emailConfigLoading: false,
      copyField: "",
      allMentionableFields: [],
      entityDocuments: [],
      saveAsTemplateDialog: false,
      isTemplateCreated: false,
      relationalDataForm: {},
      relationalTableData: [],
      ckeditorLoading: false,
      mentionedRelationalTableFields: [],
      allEntityFields: [],
    };
  },
  async mounted() {
    if (this.currentEntity?.printing_documents) {
      this.fetchAllCompanyTemplates(this.currentEntity.printing_documents);
    }
    this.emailConfigLoading = true;
    this.loadingText = "Preparing Data";
    this.allMentionableFields = await this.fetchEntityDetails(
      this.currentEntity._id,
      true,
      true
    );
    this.allEntityFields = JSON.parse(
      JSON.stringify(this.allMentionableFields)
    );
    this.getCompanyInfo();
    if (this.emailTemplateId) {
      this.fetchEmailTemplate(this.emailTemplateId);
    }
    if (this.parentEmailTemplateId) {
      this.fetchParentTemplate(this.parentEmailTemplateId);
    }
    await this.prepareData();
    if (this.isAutomation) {
      await this.getExistedData();
    }
    this.emailConfigLoading = false;
  },
  mixins: [AutomationHelper, EntityHelper, templateBuilderHelper],
  components: {
    singleEntityTemplatesList: () =>
      import("@/components/emailTemplates/singleEntityTemplatesList.vue"),
    saveAsTemplate: () =>
      import("@/components/emailTemplates/saveAsTemplate.vue"),
    emailRelationalDataTable: () =>
      import("@/components/notifications/emailRelationalDataTableConfig.vue"),
    ChatGpt,
  },
  computed: {
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityDataById",
      "getPageSize",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
      "getSentEntityDataNotificationStatus",
      "getEntityById",
      "getBulkUpdateStatus",
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
      "getEntityDataByIdError",
      "getEntityVariableFieldsInputTypes",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
      "getDeleteFilterErros",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getParentEntityRelatiponshipData",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getActiveContactType",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
      "getActiveWorkspace",
      "getGlobalFiltervalue",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
      "getEntityMenu",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("entityEmailTemplate", [
      "getEntityEmailTemplatesData",
      "getEmailTemplateError",
      "getCurrentEmailTemplateData",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("documents", ["getAllTemplates"]),
  },
  methods: {
    async fetchEmailTemplate(id) {
      let params = {
        template_id: id,
      };
      await this.$store.dispatch(
        "entityEmailTemplate/currentTemplateData",
        params
      );
      if (this.getCurrentEmailTemplateData?._id) {
        this.entityNotificationBody.subject = this.parseEmailTemplate(
          /\[\[([^[\]]*)\]\]/g,
          this.getCurrentEmailTemplateData.subject,
          this.allEntityFields,
          true
        );
        this.entityNotificationBody.body =
          // this.getCurrentEmailTemplateData.content;
          this.parseEmailTemplate(
            /\[\[([^[\]]*)\]\]/g,
            this.getCurrentEmailTemplateData.content,
            this.allEntityFields,
            true
          );
        if (
          this.getCurrentEmailTemplateData.mentionedRelationalTableFields
            ?.length &&
          this.getCurrentEmailTemplateData?.relationalTableData?.length
        ) {
          this.mentionedRelationalTableFields = [
            ...this.getCurrentEmailTemplateData?.mentionedRelationalTableFields,
          ];
          this.relationalTableData = [
            ...this.getCurrentEmailTemplateData?.relationalTableData,
          ];
        } else {
          this.mentionedRelationalTableFields = [];
          this.relationalTableData = [];
        }
      }
    },
    async fetchParentTemplate(id) {
      let response = await postAPICall(
        "GET",
        `entity-email-template/${id}`,
      );
      if (response?.data) {
        this.email_parent_content = {
          header_body: response.data.header_content,
          footer_body: response.data.footer_content,
          name: response.data.name,
        };
      } else {
        this.resetParent();
      }
    },
    async fetchAllCompanyTemplates(ids) {
      await this.$store.dispatch("documents/fetchAllTemplates", {
        include_configure_templates: ids,
      });
      this.entityDocuments =
        this.getAllTemplates && this.getAllTemplates.data
          ? this.getAllTemplates.data
          : [];
      //if default first option not working
      if (this.entityDocuments.length) {
        this.sendDataAsAttachmentDocument =
          this.entityDocuments[0].configurable_document_id;
      }
    },
    toggleAI() {
      this.showAskAi = !this.showAskAi;
    },

    CloseChatBot() {
      this.showAskAi = false;
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX - this.left;
      this.startY = event.clientY - this.top;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        this.left = event.clientX - this.startX;
        this.top = event.clientY - this.startY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },

    showAskAIMethod() {
      this.showAskAi = true;
    },
    handleCloseAskAI() {
      this.showAskAi = false;
    },
    handleCopyContent(data) {
      if (data.isAppend) {
        this.entityNotificationBody.body += data.content;
      } else {
        this.entityNotificationBody.body = data.content;
      }
    },

    getEntityFields(fields) {
      if (fields && fields.length && this.isAutomation) {
        return fields;
      }
      return [];
    },
    emailTemplateListDialog() {
      this.emailTemplatesListDialog = false;
    },
    emailTemplateParentListDialog() {
      this.emailTemplatesParentListDialog = false;
    },
    ConfigureTableDialog() {
      this.configureTableDialog = false;
    },
    checkIsDisabled() {
      if (
        !this.email_fields.filters?.length &&
        !this.email_fields.user_types_emails?.length &&
        !this.email_fields.custom_emails?.length &&
        !this.email_fields.entity_fields?.length
      ) {
        return true;
      }
      return false;
    },
    resetEntityNotification() {
      this.entityNotificationBody.subject = "";
      this.entityNotificationBody.body = "";
      this.entityNotificationBody.filters = [];
      this.openEntityNotification = false;
      // this.selectedEntityRelationships = [];
      this.email_fields = {
        user_types_emails: [],
        entity_fields: [],
        custom_emails: [],
        filters: [],
      };
      this.bcc_email_fields = {
        user_types_emails: [],
        entity_fields: [],
        custom_emails: [],
        filters: [],
      };
      this.cc_email_fields = {
        user_types_emails: [],
        entity_fields: [],
        filters: [],
        custom_emails: [],
      };
      this.checkBCC = false;
      this.checkCC = false;
      this.checkTo = true;
      this.email_parent_content = {
        header_body: "",
        footer_body: "",
        name: "",
      };
      this.files = [];
      this.hasAttachment = false;
      this.selectedDocuments = [];
      this.form = {
        entity_id: "",
        selectedFields: [],
        filters: "",
      };
      this.sendDataAsAttachment = false;
      this.showHiddenFields = true;
      this.showTableHiddenFields = true;
      this.sendDataAsAttachmentName = "";
      this.sendDataAsAttachmentDocument = "";
    },
    chooseFromEmailTemplates() {
      this.emailTemplatesListDialog = true;
      this.componentCallType = "ENTITY";
    },
    handleSaveAsTemplate() {
      let isValid = this.validateContent();
      if (!isValid.value) {
        return this.$message.warning(isValid.message);
      }
      let pattern = /\[\[([^[\]]*)\]\]/g;
      let mentionedSubjectLabels = [];
      let labelAndFields = {};
      this.allMentionableFields.map((e) => {
        labelAndFields[e.label] = e;
      });
      let match;
      let isSubjectValid = true;
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "ENTITY_TABLE",
        "MULTI_LINE_TEXT",
        "GIF",
      ];
      while (
        (match = pattern.exec(this.entityNotificationBody.subject)) !== null
      ) {
        mentionedSubjectLabels.push(match[1]);
      }
      mentionedSubjectLabels.map((e) => {
        const template = labelAndFields[e];
        if (!template) {
          isSubjectValid = false;
        }
        if (notSupportedSubjectTypes.includes(template?.input_type)) {
          isSubjectValid = false;
        }
      });
      if (!isSubjectValid) {
        return this.$message.warning(
          "Please remove Invalid fields from your subject"
        );
      }
      this.saveAsTemplateForm = {
        subject: this.entityNotificationBody.subject,
        content: this.entityNotificationBody.body,
        entity_id: this.currentEntity._id,
      };
      if (
        this.entityNotificationBody.body.includes(
          "[[relationalEntityDataTable]]"
        )
      ) {
        this.relationalDataForm = { ...this.form };
      }
      this.saveAsTemplateDialog = true;
    },
    closeSaveAsTemplateDialog() {
      this.saveAsTemplateDialog = false;
    },
    handleTemplateCreated() {
      this.saveAsTemplateDialog = false;
      this.isTemplateCreated = true;
    },
    async openSendNotification() {
      this.emailFields = [];
      this.docFields = [];
      let entityVariablesAndEntityFields = [];
      let fieldsObject = {};
      let standardTemplateData = await axios.get("/get-standard-template");
      let standardTemplate = standardTemplateData.data.data;
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "ENTITY_TABLE",
        "MULTI_LINE_TEXT",
        "GIF",
      ];
      //getting all doc and email fields
      this.allMentionableFields = this.allMentionableFields.map((e) => {
        if (e.input_type === "ENTITY_VARIABLE" || e.input_type === "ENTITY") {
          entityVariablesAndEntityFields.push(e);
          if (
            e.global_variable_entity_field == `${standardTemplate._id}#email`
          ) {
            this.emailFields.push(e);
          }
        } else if (
          e.input_type == "SIGNATURE"
          // e.type == "CONTENT"
          // (e.properties?.filed_content && e.properties?.filed_content == "Hide")
        ) {
          return;
        } else if (e.input_type == "FILE") {
          this.docFields.push(e);
        } else if (e.input_type == "EMAIL") {
          this.emailFields.push(e);
        } else if (e.template_key == `${standardTemplate._id}#email`) {
          this.emailFields.push(e);
        }
        //generating new object with key and field
        fieldsObject[e.key] = e;
        return {
          label: e.label,
          template_key: e.template_key,
          id: e.template_key,
          input_type: e.inputType,
          key: e.key,
          isSubjectSupported: !notSupportedSubjectTypes.includes(e.inputType),
        };
      });
      this.allMentionableFields = this.allMentionableFields.filter(
        (e) => e != undefined || e != null
      );
      if (
        entityVariablesAndEntityFields &&
        entityVariablesAndEntityFields.length
      ) {
        await this.$store.dispatch("entities/fetchEntityFieldsInputTypes", {
          entityVariablesAndEntityFields,
        });
        if (this.getEntityVariableFieldsInputTypes) {
          await (this.getEntityVariableFieldsInputTypes || []).map((e) => {
            if (e.input_type == "FILE") {
              let field = fieldsObject[e.key];
              if (field) {
                this.docFields.push(field);
              }
            } else if (e.input_type == "EMAIL") {
              let field = fieldsObject[e.key];
              if (field) {
                this.emailFields.push(field);
              }
            }
          });
        }
      }
      this.docFields = Array.from(new Set(this.docFields));
      this.emailFields = Array.from(new Set(this.emailFields));
      this.loading = false;
      this.editorConfig.height = 300;
      this.editorConfig.width = 700;
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            // '<span class="fullname">{fullname}</span>' +
            "</li>",
          outputTemplate: (item) => {
            if (
              item.input_type == "IMAGE" ||
              item.inputType == "IMAGE" ||
              item.input_type == "GIF"
            ) {
              return `<img alt="${item.label}" 
                            src="https://app-esigns-io.s3.amazonaws.com/ck_editor_assets/MicrosoftTeams-image_8-1716639572596.png?field=[[${item.label}]]" 
                            style="width: 80px;"
                            title="${item.label}" />`;
            } else {
              return `<span>&nbsp;</span><span>[[${item.label}]]</span><span>&nbsp;</span>`;
            }
          },
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
        },
        {
          feed: this.companyDataFeed,
          marker: "#",
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><strong>(({id}))</strong><span>&nbsp;</span>",
          minChars: 0,
        },
      ];
      this.selectedNotifyEntity = this.entityData || {};
      this.selectedEntityRelationships = [];
      if (this.getEntityRelationships) {
        this.selectedEntityRelationships = [...this.getEntityRelationships];
        // we have to add type owner of relationship
        this.selectedEntityRelationships =
          await this.selectedEntityRelationships.map((e) => {
            e.entity_id = this.currentEntity._id;
            if (
              e.parent_entity_id == this.currentEntity._id ||
              e.parent_entity_id?._id == this.currentEntity._id
            ) {
              e.owner_type = "PARENT";
              e.relationship_id = e.child_entity_id?._id || e.child_entity_id;
              e.relationship_title = e.child_relation_title;
              e.relationship_entity_type = e.child_entity_id?.entity_type || "";
              e.entity_name = e.child_entity_id?.name || "";
            } else {
              e.owner_type = "CHILD";
              e.relationship_id = e.parent_entity_id?._id || e.parent_entity_id;
              e.relationship_title = e.parent_relation_title;
              e.relationship_entity_type =
                e.parent_entity_id?.entity_type || "";
              e.entity_name = e.parent_entity_id?.name || "";
            }
            return e;
          });
        //remove general entities
        this.selectedEntityRelationships =
          this.selectedEntityRelationships.filter(
            (relation) => relation?.relationship_entity_type != "GENERAL"
          );
        this.selectedEntityRelationships =
          this.selectedEntityRelationships.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
      }
      if (this.emailFields.length) {
        this.email_fields.entity_fields.push(this.emailFields[0].template_key);
      } else if (this.selectedEntityRelationships.length) {
        this.email_fields.filters.push(
          `RELATION#${this.selectedEntityRelationships[0]._id}`
        );
      } else if (
        this.userTypesEmails.length &&
        this.userTypesEmails[0].options &&
        this.userTypesEmails[0].options.length
      ) {
        this.email_fields.user_types_emails.push(
          this.userTypesEmails[0].options[0]
        );
      }
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        this.entityNotificationBody.filters = ["SELF"];
      } else if (this.currentEntity?.entity_type == "BUSINESS") {
        this.entityNotificationBody.filters = ["CONTACTS"];
      }
      if (this.checkBulkSendCondition()) {
        await this.$store.dispatch("filters/fetchAllEntityFilters", {
          entityId: this.currentEntity._id,
        });
        if (this.getAllEntityFilters) {
          this.selectedNotifyEntityFilters = this.getAllEntityFilters;
        }
      }
    },
    async getExistedData() {
      this.loadingText = "Fetching existed data";
      let data = this.paramsContent;
      if (data.body) {
        data.body = data.body.replace(/\[\[(.*?)\]\]/g, (match, key) => {
          const template = this.allMentionableFields.find((obj) => {
            const parts = obj.template_key.split("#");
            return (
              parts[0] === key.split("#")[0] && parts[1] === key.split("#")[1]
            );
          });
          return template ? `[[${template?.label}]]` : match;
        });
        if (data.body.includes("{{relationalEntityDataTable}}")) {
          data.body = data.body.replace(
            "{{relationalEntityDataTable}}",
            "[[relationalEntityDataTable]]"
          );
        }
        this.entityNotificationBody.body = data.body;
      }
      if (data.subject) {
        data.subject = data.subject.replace(/\[\[(.*?)\]\]/g, (match, key) => {
          const template = this.allMentionableFields.find((obj) => {
            const parts = obj.template_key.split("#");
            return (
              parts[0] === key.split("#")[0] && parts[1] === key.split("#")[1]
            );
          });
          return template ? `[[${template?.label}]]` : match;
        });
        this.entityNotificationBody.subject = data.subject;
      }
      if (data.attachment_fields && data.attachment_fields.length) {
        this.hasAttachment = true;
        this.selectedDocuments = [...data.attachment_fields];
      }
      if (data.bcc_email_fields && Object.keys(data.bcc_email_fields).length) {
        this.checkBCC = true;
        this.bcc_email_fields = {
          ...data.bcc_email_fields,
        };
      }
      if (data.cc_email_fields && Object.keys(data.cc_email_fields).length) {
        this.checkCC = true;
        this.cc_email_fields = {
          ...data.cc_email_fields,
        };
      }
      if (data.email_fields && Object.keys(data.email_fields).length) {
        this.email_fields = {
          ...data.email_fields,
        };
      }
      if (
        data.mentionedRelationalTableFields?.length &&
        data?.relationalTableData?.length
      ) {
        this.mentionedRelationalTableFields = [
          ...data?.mentionedRelationalTableFields,
        ];
        this.relationalTableData = [...data?.relationalTableData];
      }
      if (data.send_data_as_attachment) {
        this.sendDataAsAttachment = true;
        data.send_data_as_attachment_name =
          data.send_data_as_attachment_name.replace(
            /\[\[(.*?)\]\]/g,
            (match, key) => {
              const template = this.allMentionableFields.find((obj) => {
                const parts = obj.template_key.split("#");
                return (
                  parts[0] === key.split("#")[0] &&
                  parts[1] === key.split("#")[1]
                );
              });
              return template ? `[[${template?.label}]]` : match;
            }
          );
        this.sendDataAsAttachmentName = data.send_data_as_attachment_name;
        this.sendDataAsAttachmentDocument =
          data.send_data_as_attachment_document ||
          this.currentEntity.default_printing_document;
      }
      if (!data.show_hidden_fields) {
        this.showHiddenFields = false;
      }
      if (!data.show_table_hidden_fields) {
        this.showTableHiddenFields = false;
      }
      if (data.attachments && data.attachments.length) {
        this.loadingText = "Fetching attachments";
        this.hasAttachment = true;
        let params = {
          paths: data.attachments,
        };
        await this.$store.dispatch(
          "s3FileUpload/getDataFromPresignedUrls",
          params
        );
        if (this.getFileUploadData) {
          this.files = [...this.getFileUploadData];
        }
      }
      if (
        data.email_parent_content &&
        Object.keys(data.email_parent_content).length
      ) {
        this.email_parent_content = {
          ...data.email_parent_content,
        };
      }
    },
    sendNotification() {
      this.emailConfigLoading = true;
      let isValid = this.validateContent();
      if (!isValid.value) {
        this.emailConfigLoading = false;
        return this.$message.warning(isValid.message);
      }
      const childRegex = /\[\[(.*?)\]\]/g;
      const childVariables = [];
      let hasHtml = false;
      let htmlFields = ["DATA_TABLE", "RICH_TEXT", "ENTITY_TABLE"];
      let labelAndFields = {};
      this.allMentionableFields.map((e) => {
        labelAndFields[e.label.toString()] = e;
      });
      let match;
      while (
        (match = childRegex.exec(this.entityNotificationBody.body)) !== null
      ) {
        if (
          htmlFields.includes(labelAndFields[match[1]?.toString()]?.input_type)
        ) {
          hasHtml = true;
        }
        childVariables.push(match[1]);
      }
      const customRegex = /\(\(([^)]+)\)\)/g;
      let customVariables = [];
      while (
        (match = customRegex.exec(this.entityNotificationBody.body)) !== null
      ) {
        customVariables.push(match[1]);
      }
      let mentionedFields = [];
      let mentionedSubjectFields = [];
      let mentionedAttachmentNameFields = [];
      let variables = {};
      // let filters = [];
      let isSubjectValid = true;
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "ENTITY_TABLE",
        "MULTI_LINE_TEXT",
        "GIF",
      ];
      let pattern = /\[\[([^[\]]*)\]\]/g;
      let mentionedSubjectLabels = [];

      while (
        (match = pattern.exec(this.entityNotificationBody.subject)) !== null
      ) {
        mentionedSubjectLabels.push(match[1]);
      }
      mentionedSubjectLabels.map((e) => {
        const template = labelAndFields[e?.toString()];
        if (!template) {
          isSubjectValid = false;
        }
        if (notSupportedSubjectTypes.includes(template?.input_type)) {
          isSubjectValid = false;
        }
      });
      if (!isSubjectValid) {
        return this.$message.warning(
          "Please remove Invalid fields from your subject"
        );
        // this.$alert("Do you want to remove unsupported fields", "Title", {
        //   confirmButtonText: "OK",
        //   callback: () => {
        //     return;
        //   },
        // });
      }
      this.entityNotificationBody.subject =
        this.entityNotificationBody.subject.replace(
          /\[\[(.*?)\]\]/g,
          (match, label) => {
            const template = labelAndFields[label?.toString()];
            if (!template) {
              return "-";
            }
            mentionedSubjectFields.push(template.template_key);
            return template ? `[[${template?.template_key}]]` : match;
          }
        );
      // if (
      //   this.isSendIndividual ||
      //   (!this.isSendIndividual &&
      //     ((this.hasAttachment &&
      //       this.selectedDocuments &&
      //       this.selectedDocuments.length) ||
      //       this.sendDataAsAttachment ||
      //       (mentionedSubjectFields && mentionedSubjectFields.length) ||
      //       hasHtml)) ||
      //   this.checkHasCustomEmail()
      // ) {
      this.entityNotificationBody.body =
        this.entityNotificationBody.body.replace(
          /\[\[(.*?)\]\]/g,
          (match, label) => {
            if (label.includes("DataTable-")) {
              return `[[${label}]]`;
            }
            const template = labelAndFields[label?.toString()];
            if (!template) {
              return "--";
            }
            if (template?.input_type == "PAY_BUTTON") {
              mentionedFields.push(`${template?.template_key}#pay`);
              return template ? `[[${template.template_key}#pay]]` : match;
            }
            mentionedFields.push(template.template_key);
            return template ? `[[${template?.template_key}]]` : match;
          }
        );
      // }
      // else {
      //   this.entityNotificationBody.body =
      //     this.entityNotificationBody.body.replace(
      //       /\[\[(.*?)\]\]/g,
      //       (match, label) => {
      //         const template = labelAndFields[label?.toString()];
      //         if (!template) {
      //           return "--";
      //         }
      //         if (template?.input_type == "PAY_BUTTON") {
      //           mentionedFields.push(`${template?.template_key}#pay`);
      //           variables[`${template?.template_key}#pay`] = template.key;
      //           return template ? `{{${template.key}}}` : match;
      //         }
      //         mentionedFields.push(template.template_key);
      //         variables[template.template_key] = template.key;
      //         return template ? `{{${template.key}}}` : match;
      //       }
      //     );
      //   this.entityNotificationBody.body =
      //     this.entityNotificationBody.body.replace(
      //       /\(\(([^)]+)\)\)/g,
      //       "{{$1}}"
      //     );
      // }
      this.paramsContent = {
        entity_type: this.currentEntity.entity_type,
        subject: this.entityNotificationBody.subject,
        body: this.entityNotificationBody.body,
        entity_id: this.currentEntity._id,
        mentioned_fields: mentionedFields,
        email_fields: this.email_fields,
      };
      if (this.isSendIndividual && this.selectedNotifyEntity?._id) {
        // this.paramsContent["filters"] = filters;
        this.paramsContent["entity_data_id"] = this.selectedNotifyEntity._id;
      } else {
        // this.paramsContent["email_fields"] = this.email_fields;
        this.paramsContent["variables"] = variables;
        if (hasHtml) {
          this.paramsContent["hasHtml"] = true;
        }
        if (this.selectedIds && this.selectedIds?.length) {
          this.paramsContent["entity_data_ids"] = this.selectedIds;
        }
        if (
          (this.hasAttachment &&
            this.selectedDocuments &&
            this.selectedDocuments.length) ||
          this.sendDataAsAttachment ||
          (mentionedSubjectFields && mentionedSubjectFields.length) ||
          hasHtml ||
          this.checkHasCustomEmail()
        ) {
          this.paramsContent["send_individually"] = true;
        }
      }
      if (this.checkBCC) {
        this.paramsContent["bcc_email_fields"] = this.bcc_email_fields;
      }
      if (this.checkCC) {
        this.paramsContent["cc_email_fields"] = this.cc_email_fields;
      }
      if (mentionedSubjectFields && mentionedSubjectFields.length) {
        this.paramsContent["mentionedSubjectFields"] = mentionedSubjectFields;
      }
      if (customVariables && customVariables.length) {
        this.paramsContent["custom_variables"] = customVariables;
      }
      if (
        this.email_parent_content.footer_body &&
        this.email_parent_content.header_body
      ) {
        this.paramsContent["email_parent_content"] = this.email_parent_content;
      }
      if (this.hasAttachment && this.files?.length) {
        this.paramsContent["attachments"] = this.getAttachmentFiles();
      }
      if (
        this.hasAttachment &&
        this.selectedDocuments &&
        this.selectedDocuments.length
      ) {
        this.paramsContent["attachment_fields"] = this.selectedDocuments;
      }
      if (this.sendDataAsAttachment) {
        this.sendDataAsAttachmentName = this.sendDataAsAttachmentName.replace(
          /\[\[(.*?)\]\]/g,
          (match, label) => {
            const template = labelAndFields[label?.toString()];
            if (!template) {
              return "-";
            }
            mentionedAttachmentNameFields.push(template.template_key);
            return template ? `[[${template?.template_key}]]` : match;
          }
        );
        this.paramsContent["send_data_as_attachment"] = true;
        this.paramsContent["send_data_as_attachment_name"] =
          this.sendDataAsAttachmentName;
        this.paramsContent["send_data_as_attachment_document"] =
          this.sendDataAsAttachmentDocument;
        this.paramsContent["mentionedAttachmentNameFields"] =
          mentionedAttachmentNameFields;
      }
      if (this.showHiddenFields) {
        this.paramsContent["show_hidden_fields"] = true;
      }
      if (this.showTableHiddenFields) {
        this.paramsContent["show_table_hidden_fields"] = true;
      }
      if (this.mentionedRelationalTableFields?.length) {
        this.paramsContent["mentionedRelationalTableFields"] =
          this.mentionedRelationalTableFields;
        this.paramsContent["relationalTableData"] = this.relationalTableData;
        this.paramsContent["send_individually"] = true;
        //clearing unwanted declarations of this format
        let regex = /\[\[DataTable-([^\]]+)\]\]/g;
        this.entityNotificationBody.body =
          this.entityNotificationBody.body.replace(
            regex,
            (match, fieldName) => {
              if (this.mentionedRelationalTableFields.includes(fieldName)) {
                return match;
              } else {
                return "";
              }
            }
          );
      }
      if (
        this.checkBulkSendCondition() &&
        this.entityNotificationBody.filter_id
      ) {
        this.paramsContent["filter_id"] = this.entityNotificationBody.filter_id;
      }
      this.emailConfigLoading = false;
      this.paramsContent.event_type = "MAIL";
      this.$emit("closeNotificationDialog", this.paramsContent);
      this.paramsContent = {};
      this.resetEntityNotification();
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allMentionableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    companyDataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allCompanyDataFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    async prepareData() {
      if (
        this.$route.path.includes("/ap/") ||
        this.$route.name == "ApEntityExecute" ||
        this.$route.name == "ApPreviewEntityData"
      ) {
        this.isApplicationUserSide = true;
      }
      this.resetEntityNotification();
      // if(!this.userTypes?.length ||
      //   !this.allEntitiesData?.length ||
      //   !this.userTypesEmails?.length) {
      // Promise.all([
      //   await this.fetchEntities(),
      //   await this.getCompanyUserTypeList(),
      //   await this.getUserTypeEmailsList(),
      // ]);
      // }
      if (this.getUserTypeEmails && this.getUserTypeList) {
        this.userTypes = this.getUserTypeList;
        this.userTypesEmails = this.getUserTypeEmails.data;
      }
      await this.fetchEntities(), await this.openSendNotification();
      this.isOpened = true;
    },
    chooseFromParentEmailTemplates() {
      this.emailTemplatesParentListDialog = true;
      this.componentCallType = "PARENT";
    },
    async closeTemplatesList() {
      this.loadingText = "Fetching data....";
      this.ckeditorLoading = true;
      if (this.componentCallType == "ENTITY") {
        this.entityNotificationBody.body = this.parseEmailTemplate(
          /\[\[([^[\]]*)\]\]/g,
          this.email_content.body,
          this.allEntityFields,
          true
        );
        this.entityNotificationBody.subject = this.parseEmailTemplate(
          /\[\[([^[\]]*)\]\]/g,
          this.email_content.subject,
          this.allEntityFields,
          true
        );
        if (
          this.email_content.mentionedRelationalTableFields?.length &&
          this.email_content?.relationalTableData?.length
        ) {
          this.mentionedRelationalTableFields = [
            ...this.email_content?.mentionedRelationalTableFields,
          ];
          this.relationalTableData = [
            ...this.email_content?.relationalTableData,
          ];
        } else {
          this.mentionedRelationalTableFields = [];
          this.relationalTableData = [];
        }
        this.ckeditorLoading = false;
        this.emailTemplatesListDialog = false;
      } else {
        // this.entityNotificationBody['parent_content'] = this.email_parent_content;
        this.emailTemplatesParentListDialog = false;
      }
      this.ckeditorLoading = false;
      this.emailConfigLoading = false;
    },
    checkHasCustomEmail() {
      if (
        this.email_fields?.custom_emails?.length ||
        this.cc_email_fields?.custom_emails?.length ||
        this.bcc_email_fields?.custom_emails?.length
      ) {
        return true;
      }
      return false;
    },
    handleTableConfigClose(data) {
      this.ckeditorLoading = true;
      this.loadingText = "Fetching Table data....";
      this.relationalTableData = [...data];
      this.mentionedRelationalTableFields = [];
      this.relationalTableData.map((e) => {
        this.mentionedRelationalTableFields.push(e.name);
        if (
          (e.type == "email_body" || e.typee == "dataTable") &&
          !this.entityNotificationBody.body.includes(`[[DataTable-${e.name}]]`)
        ) {
          // let key = Math.floor(Math.random() * 100000);
          this.entityNotificationBody.body =
            this.entityNotificationBody.body +
            `<br> [[DataTable-${e.name}]] &nbsp;&nbsp;`;
        }
      });
      let regex = /\[\[DataTable-([^\]]+)\]\]/g;
      this.entityNotificationBody.body =
        this.entityNotificationBody.body.replace(regex, (match, fieldName) => {
          if (this.mentionedRelationalTableFields.includes(fieldName)) {
            return match;
          } else {
            return "";
          }
        });

      this.configureTableDialog = false;
      this.ckeditorLoading = false;
    },
    // async getCompanyUserTypeList() {
    //   if (this.getActiveWorkspace && this.getActiveWorkspace?.company_id) {
    //     try {
    //       await this.$store.dispatch("auth/getUserTypeList", {
    //         companyId: this.getActiveWorkspace.company_id,
    //         isMenu: true,
    //       });
    //       if (this.getUserTypeList) {
    //         this.userTypes = this.getUserTypeList;
    //       }
    //     } catch (err) {
    //       this.$notify.error({
    //         title: "Error",
    //         message: err,
    //       });
    //     }
    //   }
    // },
    // async getUserTypeEmailsList() {
    //   try {
    //     this.userEmailsLoading = true;
    //     if (!this.userTypes.length) {
    //       this.userEmailsLoading = false;
    //       return ;
    //     }
    //     let AllUserTypeIds = this.userTypes.map((e) => e._id);
    //     let params = {
    //       userTypes: AllUserTypeIds,
    //     };
    //     await this.$store.dispatch("auth/fetchUserTypeEmails", params);
    //     if (this.getUserTypeEmails) {
    //       this.userTypesEmails = this.getUserTypeEmails.data;
    //       this.userEmailsLoading = false;
    //     }
    //   } catch (err) {
    //     this.userEmailsLoading = false;
    //     this.$notify.error({
    //       title: "Error",
    //       message: this.getUserTypeEmailsErrors,
    //     });
    //   }
    //   this.userEmailsLoading = false;
    //   return;
    // },
    getLabelByUserTypeId(userTypeId) {
      let userTypeData = this.userTypes.filter((e) => e._id == userTypeId);
      if (userTypeData?.[0] && userTypeData?.[0].role) {
        return userTypeData?.[0].role.title + " - " + userTypeData?.[0]?.name;
      } else {
        return userTypeData?.[0].name;
      }
    },
    // handleToEmailChange(type) {
    //   switch (type) {
    //     case "custom":
    //       this.email_fields.user_types_email = "";
    //       this.email_fields.entity_field = "";
    //       this.email_fields.relational_field = "";
    //       break;
    //     case "entity_field":
    //       this.email_fields.custom_email = "";
    //       this.email_fields.user_types_email = "";
    //       this.email_fields.relational_field = "";
    //       break;
    //     case "user_type_email":
    //       this.email_fields.custom_email = "";
    //       this.email_fields.entity_field = "";
    //       this.email_fields.relational_field = "";
    //       break;
    //     case "relational_field":
    //       this.email_fields.custom_email = "";
    //       this.email_fields.entity_field = "";
    //       this.email_fields.user_types_email = "";
    //       break;
    //     default:
    //       this.email_fields.custom_email = "";
    //       this.email_fields.user_types_email = "";
    //       this.email_fields.entity_field = "";
    //       this.email_fields.relational_field = "";
    //       break;
    //   }
    // },
    async getCompanyInfo() {
      const fieldMappings = {
        about_company_text: "Company - Description",
        email_domain: "Company - Email Domain",
        email_footer_text: "Company - Email Footer",
        legal_name: "Company - Legal Name",
        name: "Company - Name",
        logo: "Company - Logo",
        address: "Company - Address",
      };
      this.allCompanyFields = Object.entries(fieldMappings).map(
        ([field, label]) => {
          return {
            label,
            id: field,
          };
        }
      );
      this.allCompanyDataFields = [
        ...this.allCustomFields,
        ...this.allCompanyFields,
      ];
      const userFieldMappings = {
        first_name: "Login User - First Name",
        last_name: "Login User - Last Name",
        email: "Login User - Email",
        phone: "Login User - Phone",
        address: "Login User - Address",
        owner_first_name: "Owner - First Name",
        owner_last_name: "Owner - Last Name",
        owner_email: "Owner - Email",
        owner_phone: "Owner - Phone",
        owner_address: "Owner - Address",
      };
      let userFields = Object.entries(userFieldMappings).map(
        ([field, label]) => {
          return {
            label,
            value: field,
            id: field,
          };
        }
      );
      this.allUserFields = userFields;
      this.allCompanyDataFields = [...this.allCompanyDataFields, ...userFields];
    },
    uploadFile(file) {
      if (
        file.type != "audio/mpeg" &&
        file.type != "video/mp4" &&
        file.type != "image/gif" &&
        file.type != "text/html" &&
        file.type != "text/css" &&
        file.type != "text/javascript" &&
        file.type != "application/json" &&
        file.type != "" &&
        file.type != "application/x-msdownload"
      ) {
        let totalSize = this.files.reduce((total, file) => {
          return total + file.size;
        }, 0);

        if (file.size < 5000000 && totalSize < 10000000) {
          const reader = new FileReader();

          reader.onload = (event) => {
            file.base64 = event.target.result.split(",")[1];
            const fileType = event.target.result.split(",")[0];
            file.type = fileType.split(";")[0].split(":")[1];
            this.files.push(file);
          };

          reader.onerror = (error) => {
            console.error("Error reading the file:", error);
          };

          reader.readAsDataURL(file.raw);
        } else {
          this.$message.error("Large files. Maximum upload file size: 10 MB");
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    resetParent() {
      this.email_parent_content = {
        header_body: "",
        footer_body: "",
        name: "",
      };
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    getAttachmentFiles() {
      let data = this.files.map((e) => {
        return {
          filename: e.name,
          type: e.raw.type,
          content: e.base64,
          size: e.size,
        };
      });
      return data;
    },
    checkBulkSendCondition() {
      if (
        !this.isSendIndividual &&
        !(this.selectedIds && this.selectedIds?.length)
      ) {
        return true;
      }
      return false;
    },
    validateContent() {
      if (!this.entityNotificationBody.subject.trim()) {
        return {
          value: false,
          message: "Please Enter Subject",
        };
      } else if (this.sendDataAsAttachment && !this.sendDataAsAttachmentName) {
        return {
          value: false,
          message: "Please Enter Printing Name",
        };
      } else if (
        !(
          this.entityNotificationBody.body &&
          this.entityNotificationBody.body.length > 20
        )
      ) {
        return {
          value: false,
          message: "Email body must be greater than 20 characters",
        };
      }
      const leftBracesCount =
        this.entityNotificationBody.body.split("[[").length - 1;
      const rightBracesCount =
        this.entityNotificationBody.body.split("]]").length - 1;
      if (leftBracesCount < rightBracesCount) {
        return {
          value: false,
          message: "Please remove unwanted ]]",
        };
      } else if (leftBracesCount > rightBracesCount) {
        return {
          value: false,
          message: "Please remove unwanted [[",
        };
      }
      const regex = /\[\[\s*]]/g;
      if (regex.test(this.entityNotificationBody.body)) {
        return {
          value: false,
          message: "There should be field inside [[ ]]",
        };
      }
      // Check for [[ before ]]
      if (/\[\[[^\]]*\[\[/.test(this.entityNotificationBody.body)) {
        return {
          value: false,
          message: "No [[ allowed inside double brackets",
        };
      }

      //checking custom variables
      const leftParenthesesCount =
        this.entityNotificationBody.body.split("((").length - 1;
      const rightParenthesesCount =
        this.entityNotificationBody.body.split("))").length - 1;

      if (leftParenthesesCount < rightParenthesesCount) {
        return {
          value: false,
          message: "Please remove unwanted ))",
        };
      } else if (leftParenthesesCount > rightParenthesesCount) {
        return {
          value: false,
          message: "Please remove unwanted ((",
        };
      }

      const regexParentheses = /\(\(\s*\)\)/g;
      if (regexParentheses.test(this.entityNotificationBody.body)) {
        return {
          value: false,
          message: "There should be content inside (( ))",
        };
      }

      // Check for (( before ))
      if (/\(\((^\))*\(\(/.test(this.entityNotificationBody.body)) {
        return {
          value: false,
          message: "No (( allowed inside double parentheses",
        };
      }
      return {
        value: true,
      };
    },
    async fetchEntities() {
      try {
        // this.loading = true;
        let params = {
          get_all: true,
        };
        let relationalEntityIds = [];
        this.loadingText = "Fetching Relationships...";
        await Promise.all([
          this.$store.dispatch("entities/fetchEntities", params),
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.currentEntity._id,
            include_type: true,
          }),
        ]);
        if (this.getEntityRelationships) {
          // this.relationshipsData = this.getEntityRelationships.filter(
          //   (e) => {
          //     if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          //       return false;
          //     } else {
          //       return true;
          //     }
          //   }
          // );
          (this.getEntityRelationships || []).map((e) => {
            // if (e.owner_type == "CHILD" || e.representation == "TAB") {
            relationalEntityIds = [
              ...relationalEntityIds,
              e.child_entity_id?._id,
              e.parent_entity_id?._id,
            ];
            // }
          });
          relationalEntityIds = Array.from(new Set(relationalEntityIds));
          if (relationalEntityIds && relationalEntityIds.length) {
            let indexToDelete = relationalEntityIds.indexOf(
              this.currentEntity._id
            );
            if (indexToDelete !== -1) {
              relationalEntityIds.splice(indexToDelete, 1);
            }
          }
        }
        this.allEntitiesData = (this.getAllEntities.data || []).filter((e) => {
          if (relationalEntityIds.includes(e._id)) {
            return e;
          }
          return null;
        });
        // this.loading = false;
      } catch (err) {
        // this.loading = false;
        console.log("fetchEntities", err);
      }
    },
    async getEntityFilters(entity_id) {
      this.loadingText = "Fetching filters";
      this.filterLoading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id,
      });
      this.entityAllFilters = [];
      if (this.form.filters) {
        this.form.filters = "";
      }
      if (this.getAllEntityFilters) {
        this.entityAllFilters = this.getAllEntityFilters;
      }
      this.form.selectedFields = [];
      this.selectedEntityFields = await this.fetchEntityDetails(
        entity_id,
        true,
        true
      );
      this.selectedEntityFields = this.selectedEntityFields.filter(
        (e) =>
          e.properties?.filed_content != "Hide" &&
          !["ENTITY_TABLE", "DATA_TABLE"].includes(e.input_type)
      );
      this.filterLoading = false;
    },
    openTableConfig() {
      this.configureTableDialog = true;
    },
    handleTableAdd() {
      if (
        !this.entityNotificationBody.body.includes(
          "[[relationalEntityDataTable]]"
        )
      ) {
        this.entityNotificationBody.body =
          this.entityNotificationBody.body +
          "<br> [[relationalEntityDataTable]]";
      }
      this.configureTableDialog = false;
    },
    copyURL(field) {
      navigator.clipboard.writeText(`[[${field}]]`);
      this.$message({
        message: `${field} Field Copied!`,
        type: "success",
      });
    },
    pushToSubject(field) {
      this.entityNotificationBody.subject =
        this.entityNotificationBody.subject + `[[${field}]]`;
      this.$message({
        message: `${field} added to body`,
        type: "success",
      });
    },
    checkFormDisabled() {
      if (!this.form.entity_id || !this.form.selectedFields.length) {
        return true;
      }
      return false;
    },
    // checkSaveAsTemplateDisabled() {
    //   let isValid = this.validateContent();
    //   return isValid.value
    // },
    async prepareDataDebounced() {
      this.emailConfigLoading = true;
      this.loadingText = "Preparing data";
      await this.prepareData();
      if (this.isAutomation) {
        await this.getExistedData();
      }
      this.emailConfigLoading = false;
    },
    getSupportedSubjectFields() {
      let supportedFields = this.allMentionableFields.filter(
        (e) => e.isSubjectSupported
      );
      return supportedFields;
    },
  },
  watch: {
    entityData: {
      handler: function () {
        this.prepareDataDebounced();
      },
      deep: true,
    },

    currentEntity: {
      handler: function () {
        this.prepareDataDebounced();
      },
      deep: true,
    },

    selectedIds: {
      handler: function () {
        this.prepareDataDebounced();
      },
      deep: true,
    },

    isSendIndividual: {
      handler: function () {
        this.prepareDataDebounced();
      },
      deep: true,
    },

    keyValue: {
      handler: function () {
        this.prepareDataDebounced();
      },
      deep: true,
    },
  },
  created() {
    // this.prepareDataDebounced = _.debounce(this.prepareDataDebounced, 300);
    this.prepareDataDebounced = debounce(this.prepareDataDebounced, 300);
  },
};
</script>
<style lang="scss" scoped>
.name-types {
  .filename {
    height: 35px;
    margin-top: 5px;
    cursor: pointer;
    background: #ffffff;
    .el-upload-list__item-name {
      .\-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}
.info-button {
  border: none;
  margin-left: 10px;
  padding: 2px;
}
.document {
  width: 180px;
  margin: 10px;
}
.attachments {
  display: flex;
  justify-content: center;
  margin: 5px;
}
.upload-demo {
  margin: 10px;
}
.attachments-footer {
  margin-bottom: 50px;
}
.last-col {
  padding: 0px 40px;
}
.pop-up-button {
  cursor: pointer;
  color: #409eff;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}
.second-row {
  margin-top: 5px;
}
.email-field {
  width: 95%;
}
.para-class {
  margin-bottom: 0.5rem;
}
.el-textarea__inner {
  line-height: 1 !important;
}
.parent-template-class {
  margin-top: 20px;
  text-align: center;
}
.dialog-footer {
  width: 120%;
  display: flex;
  float: right;
  margin-right: -20px;
  padding: 8px;
  background-color: #eee;
  justify-content: end;
  border-top: 1px solid #ccc;
}
</style>
<style scoped>
.chatgpt {
  position: fixed;
  right: -12px;
  top: 100px;
  width: 27%;
}
</style>
